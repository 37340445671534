<template>
  <BaseModal
    :title="_T('@Cart')"
    :modal-width="1040"
    @on-close-modal="onCloseModal"
  >
    <EmptyCart v-if="isEmpty" :close-cart="onCloseModal" />
    <div v-else class="cart">
      <ul class="cart__list">
        <CartProduct
          v-for="cartProduct in cart"
          :key="cartProduct.product.id"
          :cart-product="cartProduct"
          :close-cart="onCloseModal"
        />
      </ul>
      <CartDelivery />
      <CartRelatedCarousel />
    </div>
    <template v-if="!isEmpty" #footer>
      <CartFooter :close-cart="onCloseModal" />
    </template>
  </BaseModal>
</template>

<script setup>
import { useCartStore } from "~/store/cartStore";
import BaseModal from "~/modules/shared/modals/BaseModal.vue";
import {
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import EmptyCart from "~/modules/cart/components/EmptyCart.vue";
import CartDelivery from "~/modules/cart/components/CartDelivery.vue";
import CartProduct from "~/modules/cart/components/CartProduct.vue";
import CartFooter from "~/modules/cart/components/CartFooter.vue";
import CartRelatedCarousel from "~/modules/cart/components/CartRelatedCarousel.vue";

const cartStore = useCartStore();
const modalStore = useModalStore();
const { getCart: cart, isEmpty } = storeToRefs(cartStore);

const onCloseModal = () => {
  modalStore.toggleModal(cartModal);
};
</script>

<style lang="scss" scoped>
.cart {
  width: 100%;
  max-height: 680px;

  position: relative;

  border-radius: 16px;
  background-color: white;

  @include bigMobile {
    height: auto;
    max-height: none;

    justify-content: flex-start;
  }

  &__list {
    width: 100%;
    height: 370px;

    @include flex-container(column, flex-start);

    background-color: var(--color-sky-lightest);
    border-radius: 8px;

    padding-left: 16px;
    padding-right: 8px;
    margin-right: 8px;

    overflow-x: auto;

    @include mobile {
      height: auto;

      padding-inline: 8px;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 0;

      @include mobile {
        width: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: var(--color-sky-dark);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-base);
      border-radius: 100px;

      &:hover {
        background-color: var(--color-primary-dark);
      }
    }
  }
}
</style>
