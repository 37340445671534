<template>
  <div v-if="products?.length > 0">
    <p class="cart-related__title">
      {{ _T("@Product you may also be interested in") }}
    </p>
    <ProductCarousel
      :products="products"
      :without-navigation="true"
      :card-width="340"
      :with-grab="true"
    >
      <template #productCard="productCardProps">
        <CartRelatedProductCard :product="productCardProps.product" />
      </template>
    </ProductCarousel>
  </div>
</template>

<script setup>
import { useAPI } from "~/uses/useMyFetch";
import ProductCarousel from "~/modules/shared/sliders/ProductCarousel.vue";
import CartRelatedProductCard from "~/modules/cart/components/CartRelatedProductCard.vue";
import { Product } from "~/models/product.model";

const products = ref(null);

useAPI("/catalog/product/list/cart/related", {
  params: {
    limit: 6,
  },
}).then((data) => {
  products.value = data.map((dtoProduct) => new Product(dtoProduct));
});
</script>

<style scoped lang="scss">
.cart-related {
  &__title {
    width: max-content;
    @include font(24, 32, 700);

    margin-bottom: 8px;

    @include mobile {
      @include font(18, 26, 600);
    }
  }
}
</style>
